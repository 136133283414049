let body = document.querySelector('body');

window.addEventListener("DOMContentLoaded", () => {
    body.classList.remove('no-js');
});


/* Add Browser Class to HTML */
var browser_name = '';
isIE = /*@cc_on!@*/false || !!document.documentMode;
isEdge = !isIE && !!window.StyleMedia;
if (navigator.userAgent.indexOf("Chrome") != -1 && !isEdge) {
    browser_name = 'chrome';
} else if (navigator.userAgent.indexOf("Safari") != -1 && !isEdge) {
    browser_name = 'safari';
} else if (navigator.userAgent.indexOf("Firefox") != -1) {
    browser_name = 'firefox';
} else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
{
    browser_name = 'ie';
} else if (isEdge) {
    browser_name = 'edge';
} else {
    browser_name = 'other-browser';
}

let html = document.querySelector('html');

html.classList.add(browser_name);
/* End */

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
var TO = false;
window.addEventListener("resize", function(){
    if(TO !== false)
        clearTimeout(TO);
    TO = setTimeout(appHeight, 500);
});
appHeight();

//accordion

var acc = document.querySelectorAll(".accordion");
var i;

for (i = 0; i < acc.length; i++) {
    let item = acc[i];
    acc[i].querySelector("span").addEventListener("click", function() {
        item.classList.toggle("active");

        var panel = item.querySelector(".panel");
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
        if(panel.previousElementSibling.closest(".panel")) {
            let panel_prev = panel.previousElementSibling.closest(".panel");
            console.log(panel_prev.scrollHeight)
            panel_prev.style.maxHeight = panel_prev.scrollHeight + panel.scrollHeight + "px";
        }
    });
}

//navi
let navInput = document.querySelector("#clickNav input");

navInput.addEventListener("change", function () {
    body.classList.toggle("overflow");
});

//lazyload
var lazyLoadInstance = new LazyLoad({
});


(function headerScroll() {
    document.addEventListener("scroll", function () {
        let scroll = window.scrollY;
        if(scroll > 0) {
            document.querySelector(".header-top").classList.add("header-scroll");
            document.querySelector("body").classList.add("header-scroll");
        }
        if (scroll > 150){
            document.querySelector(".btn-sticky").classList.add("active");
        }
        else {
            document.querySelector(".header-top").classList.remove("header-scroll");
            document.querySelector("body").classList.remove("header-scroll");
            document.querySelector(".btn-sticky").classList.remove("active");
        }
    });
})();

(function hidingNav(){
    document.addEventListener("click", function (el){
        if(body.classList.contains("overflow")) {
            if(!el.target.closest(".nav-data")) {
                body.classList.remove("overflow");
                document.querySelector("#clickNav input").checked = false;
            }
        }
    });
})();


function sliderEffect(){
    let teaser = document.querySelectorAll(".slider .swiper-slide");

    for(let i = 0; i < teaser.length; i++){
        let el = teaser[i];

        el.addEventListener("mouseover", function() {
            el.classList.add("active-slide");
        });
        el.addEventListener("mouseleave", function() {
            el.classList.remove("active-slide");
        });
    }
}
sliderEffect();

try {
    const swiperTestimonials = new Swiper('.swiperTestimonials', {
        loop: false,
        pagination: {
            el: '.swiper-pagination-testimonials',
        },
        breakpoints: {
            300: {
                slidesPerView: 1.2,
                spaceBetween: 18,
            },
            767: {
                slidesPerView: 2.2,
                spaceBetween: 18,
            }
        },
        speed: 1000,
    });

    const swiperMenue = new Swiper('.swiperMenue',{
        loop: false,
        pagination: {
            el: '.swiper-pagination-menue',
        },
        breakpoints: {
            300: {
                slidesPerView: 1.2,
                spaceBetween: 18,
            },
            767: {
                slidesPerView: 2.2,
                spaceBetween: 18,
            }
        },
        speed: 1000,
    });

    const swiperGallery = new Swiper('.swiperGallery',{
        loop: true,
        pagination: {
            el: '.swiper-pagination-gallery',
        },
        breakpoints: {
            300: {
                slidesPerView: 1.2,
                spaceBetween: 18,
            },
            767: {
                slidesPerView: 2.2,
                spaceBetween: 18,
            },
            1024: {
                slidesPerView: 3.2,
                spaceBetween: 18,
            },
        },
        speed: 1000,
    });


    if(window.innerWidth >= 1024) {
        console.log(window.innerWidth)
        swiperMenue.destroy();
        swiperTestimonials.destroy();
    };
} catch (error) {}

